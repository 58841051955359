import platform_arch from '../assets/platform_arch.png';
import health from '../assets/health.jpg';
import korea from '../assets/korea.png';
import cancer from '../assets/cancer.png';
// 다른 이미지들 import...

export const PROJECT_DATA = {
  huraypositive: [
    {
      id: 'msa-core',
      title: 'MSA Core Platform 개발',
      period: '2024.01 - 현재',
      image: platform_arch,
      summary: 'MSA Core Platform 개발 (2024.01 - 현재)',
      details: [
        'MSA 환경에서 여러 서비스에서 사용하는 플랫폼을 중점적으로 개발',
        '1. 건강정보 콘텐츠 스케쥴링 및 배급 플랫폼',
        '2. 플랫폼 사용 서비스 관리 플랫폼',
        '3. 사용자 인증 및 토큰 발행 플랫폼',
        '4. 건강정보(PHR) 관리 플랫폼',
        '5. Push, SMS, Email과 같은 알림 관련 서드파티 연동 플랫폼',
        '플랫폼별 Client 개발 및 운영으로 서비스에서 사용하기 쉬운 환경 제공',
        'RestAPI 및 Kafka와 RabbitMQ를 이용한 다양한 플랫폼 이용 채널 제공',
        'Transaction Outbox Pattern 적용으로 Event Queue 환경에서의 순서 보장',
        '성능 개선을 위한 JPA + Redis 적용',
        'DB를 공유하는 유사 플랫폼(Dust & Nebula)의 플랫폼 병합 및 마이그레이션 진행',
      ],
      techStack: [
        'Spring Boot',
        'Spring Batch',
        'JPA',
        'Redis',
        'RabbitMQ',
        'Kafka',
        'MySQL',
        'Kubernetes',
        'AWS',
        'NCP',
      ],
    },
    {
      id: 'cancer-care',
      title: 'Cancer care 암환자 서비스 API 개발',
      period: '2024.07 - 2025.01',
      image: cancer,
      summary: 'Cancer care 암환자 서비스 API 개발 (2024.07 - 2025.01)',
      details: [
        '기업 내 암환자 발생 시 최적의 치료와 재발방지를 위한 컨설팅 서비스',
        'TPM으로 서버 개발 및 리딩 진행',
      ],
      techStack: ['Spring Boot', 'JPA', 'MySQL', 'QueryDSL'],
    },
    {
      id: 'samsung-one-health',
      title: '삼성전자 One Health 서비스의 내부 PHR 플랫폼 개발',
      period: '2024.08 - 2025.02',
      summary:
        '삼성전자 One Health 서비스의 내부 PHR 플랫폼 개발 (2024.08 - 2025.02)',
      details: [
        'DX 임직원 건진/식이정보 삼성헬스 통합 개발',
        '내부 PHR 처리용 플랫폼 개발',
        'JavaCC를 이용한 동적 Expression 컴파일러 관련 개발',
      ],
      techStack: ['Spring Boot', 'JPA', 'MySQL', 'Kafka', 'JavaCC'],
    },
    {
      id: 'korea-univ-platform',
      title: '고대 국제진료 플랫폼 API 개발',
      period: '2024.10 - 2025.02',
      image: korea,
      summary: '고대 국제진료 플랫폼 API 개발 (2024.10 - 2025.02)',
      details: [
        '국제진료센터 비대면 솔루션의 개발 및 구축',
        '국제 진료센터 비대면 진료 및 관리 페이지 API 개발',
        'TPM으로 서버 개발 및 리딩 진행',
        'Agora 화상대화 플랫폼 연동(RTC)',
        'Object storage의 Presigned URL을 활용한 대용량 파일 Multipart Upload 개발',
      ],
      techStack: ['Spring Boot', 'JPA', 'MySQL', 'SSE', 'NCP'],
    },
    {
      id: 'epro',
      title: 'EPRO API 개발',
      period: '2022.01 - 2022.05',
      summary: 'EPRO API 개발 (2022.01 - 2022.05)',
      details: [
        '암환자 증상 자가보고 서비스',
        '설문 및 문진 API 개발',
        'ORM을 이용한 도메인 설계 및 개발',
        '환자용 API, 의료진용 API 개발',
      ],
      techStack: ['Spring Boot', 'JPA', 'MySQL', 'AWS', 'NCP'],
    },
    {
      id: 'health-todo',
      title: '헬스투두 API 개발',
      period: '2022.06 - 2024.01',
      image: health,
      summary: '헬스투두 API 개발 (2022.06 - 2024.01)',
      details: [
        '직장인 심뇌혈관질환 예방 및 관리를 위한 개인 맞춤형 모바일 건강관리 서비스',
        '프로젝트 초기부터 기획, 클라이언트, 프론트, 사업부와 커뮤니케이션',
        'Domain, Plugin, Service 패키지로 나누어 모놀리식 아키텍처 기반으로 프로젝트 설계 및 개발',
        '타 플랫폼(MSA 환경)의 변경사항에 의존적이지 않기 위한 모듈 기반 프로젝트 설계',
        'Spring Batch를 이용한 대용량 처리 Job 개발',
        '메모리 누수로 인한 서버 다운 이슈 수정 -> 메모리 12기가 이상 차지하며 GC가 처리하지 못하고 CPU가 오버되는 문제 발생 -> 동일 현상 재현시 1-2기가 까지 메모리 낮춤',
        '1. QueryDSL을 이용한 custom query에서 다량의 데이터를 Entity로 리턴 : 지연로딩 연관관계들이 proxy객체를 가지며 메모리 낭비',
        '2. Entity 즉시, 지연 로딩 패치 메모리 누수 : 통계를 위한 유저 엔티티에 즉시/지연 로딩 양방향 관계가 N개가 걸려있던 상태. 수많은 데이터가 return되며 필요하지 않은 엔티티까지 즉시로딩하여 세팅',
        '3. 내부 공용 플랫폼에 의존적인 조회 : 설문, 건강정보 등 데이터를 서비스 서버가 아닌 공용 플랫폼 서버에 저장되어있어서 통계를 위한 데이터를 갖고오기 위해 공용 플랫폼에 다량의 데이터 전달 요청 -> 긴 레이턴시와 트래픽 발생 ',
        '결론 : 필요치 않는 양방향 연관관계 제거, 다량의 데이터 return시 entity가 아닌 dto convert리턴, 외부 플랫폼에 의존적인 조회 해결필요(CQRS 모델 등)'
      ],
      techStack: [
        'Spring Boot',
        'Spring Batch',
        'JPA',
        'Redis',
        'RabbitMQ',
        'Kafka',
        'Kubernetes',
      ],
    },
  ],
  sociallive: [
    {
      id: 'livertc',
      title: 'LiveRTC Cloud/Platform 솔루션 개발',
      period: '2020.07 - 2021.07',
      summary: 'LiveRTC Cloud/Platform 솔루션 개발 (2020.07 - 2021.07)',
      details: [
        '바바라이브 사이트 개발 및 LiveRTC 고도화',
        'WebRTC기반 화상 대화 솔루션 플랫폼 개발',
        'AWS 클라우드 환경 구축 및 운영',
      ],
      techStack: [
        'Java',
        'Spring Boot',
        'PostgreSQL',
        'JPA',
        'Node.js',
        'WebRTC',
        'AWS',
      ],
    },
  ],
  sqisoft: [
    {
      id: 'eliga-core',
      title: 'Eliga-Core',
      period: '2020.02 - 2020.07',
      summary: 'Eliga-Core (2020.02 - 2020.07)',
      details: [
        '무인주문 키오스크 통합 솔루션(Eliga Order Kiosk - EOK)',
        '스마트스페이스 구축 및 운영 솔루션',
        'Redis 도입',
        'HTML5 웹 기반의 통합운영 디지털 사이니지 솔루션',
        'fabric.js를 이용한 커스텀 레이아웃 구성 메뉴 개발',
        'SPA 개발',
      ],
      techStack: [
        'Java',
        'Spring Boot',
        'MySQL',
        'JPA',
        'Redis',
        'AWS',
        'Node.js',
        'DynamoDB',
        'NoSQL',
      ],
    },
    {
      id: 'spc-platform',
      title: 'SPC Happy Station 2.0 통합 플랫폼',
      period: '2019.05 - 2020.07',
      summary: 'SPC Happy Station 2.0 통합 플랫폼 (2019.05 - 2020.07)',
      details: [
        'SPC의 무인주문 키오스크 솔루션 통합 관리 시스템',
        '여러 브랜드를 한 솔루션으로 통합하여 관리',
        '클라우드 서비스 도입(AWS)',
        '자바 ORM 사용(JPA)',
        '통합 플랫폼 환경을 위한 관계형 DB 아키텍처 설계',
      ],
      techStack: ['Java', 'Spring Boot', 'MySQL', 'JPA', 'AWS'],
    },
    {
      id: 'smart-mirror',
      title: '신세계 스마트 미러',
      period: '2019.06 - 2019.07',
      summary: '신세계 스마트 미러 (2019.06 - 2019.07)',
      details: [
        '사용자가 일정 거리 이상 다가오면, 거리를 측정하여 광고를 재생하는 스마트 미러',
        'Arduino 개발',
        'Vue.js 도입 및 개발',
        '내부망 환경에서의 서버 세팅 및 관리',
      ],
      techStack: ['Java', 'Spring Boot', 'MySQL', 'Arduino', 'Vue.js'],
    },
    {
      id: 'cas-smart-btu',
      title: 'CAS Smart BTU',
      period: '2019.06 - 2019.07',
      summary: 'CAS Smart BTU (2019.06 - 2019.07)',
      details: [
        '사용자의 수하물 무게와 크기를 재고, 보유한 티켓을 읽어들여 수하물 규정에 맞는지 자동으로 확인해주는 키오스크',
        '첫 프론트 프로젝트',
        'Node.js와 NoSQL 첫 개발',
        '광고로 인한 스케쥴링 체크로직 개발',
      ],
      techStack: ['Node.js', 'AWS', 'NoSQL'],
    },
    {
      id: 'kt-family',
      title: 'KT 패밀리박스 및 KT KBOX',
      period: '2018.02 - 2019.05',
      summary: 'KT 패밀리박스 및 KT KBOX (2018.02 - 2019.05)',
      details: [
        'KT 가입자들의 데이터와 멤버포인트 공유 어플리케이션',
        'Jira와 Confluence, Slack 등 협업툴을 이용한 이슈 관리',
        'SVN, Git을 이용한 소스 버전 관리',
        '다양한 개발 직군의 업무 경험 (Android, Server, Operation)',
        '서비스 아키텍처의 전체적인 흐름(Flow) 파악',
        'I/A 리뷰, 코드 리뷰 등을 통한 코드 정확도 재검토',
        'KT그룹 및 계열사의 사내 커뮤니티 사이트',
        '앱 로그인 연동 API 개발',
        'Spring MVC 패턴을 활용한 파일 설계',
      ],
      techStack: ['Java', 'Spring Boot', 'PostgreSQL', 'MyBatis'],
    },
    {
      id: 'iqsbz',
      title: 'iqsbz',
      period: '2018.01 - 2018.02',
      summary: 'iqsbz (2018.01 - 2018.02)',
      details: ['에스큐아이소프트 사내 사이트 개발'],
      techStack: ['Java', 'Spring', 'MySQL', 'MyBatis', 'Trello'],
    },
  ],
};
